<template>


        <TopPanel v-on:menuBtnClicked="onButtonClicked"/>
        <CenterPanel v-bind:btnMenuPressed="btnMenuPressed"/>


    
    
    


</template>

<script>
import TopPanel from './TopPanel'
import CenterPanel from './CenterPanel'


export default {
    components: {
        TopPanel,
        CenterPanel

    },
    data() {
		return {
            btnMenuPressed:'Live'
        }
    },
    methods:{
        onButtonClicked(btn){
            this.btnMenuPressed=btn;
            //alert("PRESSED BTN: "+btn);
        }
    }
     
}
</script>

<style scoped>
    
</style>