<!-- EXAMPLE USED: https://www.log2e.com/2019/02/create-a-simple-youtube-search-app-with-vue-js/-->
<template>
<div class="card">
  <DataView class="dataView" :value="videos" :layout="displayMode" :paginator="true" :rows="9">

      <template #list="video">
          <div class="p-col-12">
              
              <VideoListItem v-bind:video="video"/>
          </div>
      </template>

      <template #grid="video">
          <!--<div>Vin: <b>{{slotProps.data.name}}</b></div>-->
          <div class="p-col-12 p-md-4">
              
              <VideoGridItem v-bind:video="video.data"/>
          </div>
      </template>
  </DataView>
  <!--<div class="container mb-3">
    <div class="d-flex mb-3">
      <div class="mr-auto">
        <h3>Search Results for "{{ reformattedSearchString }}"</h3>
      </div>
      <div class="btn-group ml-auto" role="group">
        <button
          @click="changeDisplayMode('grid')"
          type="button"
          class="btn btn-outline-secondary"
          v-bind:class="{ active: displayMode === 'grid' }"
        >
          <i class="fas fa-th"></i>
        </button>
        <button
          @click="changeDisplayMode('list')"
          type="button"
          class="btn btn-outline-secondary"
          v-bind:class="{ active: displayMode === 'list' }"
        >
          <i class="fas fa-list"></i>
        </button>
      </div>
    </div>

    <div class="card-columns" v-if="displayMode === 'grid'">
      <div class="card" v-bind:key="video.id.videoId" v-for="video in videos">
        <VideoGridItem v-bind:video="video"/>
      </div>
    </div>
    <div v-else>
      <div class="card mb-2" v-bind:key="video.id.videoId" v-for="video in videos">
        <VideoListItem v-bind:video="video"/>
      </div>
    </div>
  </div>-->
</div>
</template>

<script>
import VideoListItem from './VideoListItem';
import VideoGridItem from './VideoGridItem';

export default {
  name: 'SearchResults',
  components: {
    VideoListItem,
    VideoGridItem
  },
  data() {
    return {
      title: 'Search Results',
      displayMode: 'grid'
    };
  },
  methods: {
    changeDisplayMode(displayMode) {
      this.displayMode = displayMode;
    }
  },
  props: ['videos', 'reformattedSearchString']
};
</script>

<style scoped>
button:focus {
  box-shadow: none !important;
}

.card {
    background: #152233;
    padding: 2rem;
    padding-left: 5rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
}

.p-col-12{
  background: #152233;
}

/*
.container{
        background:#152233; 
    }
*/
</style>