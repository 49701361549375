<template>
<DataView :value="mentors" :layout="layout" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField">

    <template #list="slotProps">
        <div class="p-col-12">
            <div class="mentor-list-item">
                <img :src="slotProps.data.avatar" :alt="slotProps.data.name" class="avatarImg"/>
                <div class="mentor-list-detail">
                    <div class="mentor-name">{{slotProps.data.name}}</div>
                    <div class="mentor-description">{{slotProps.data.bio}}</div>
                    <!--<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
                    <i class="pi pi-tag mentor-category-icon"></i><span class="mentor-category">{{slotProps.data.category}}</span>-->
                </div>
                <div class="mentor-list-action">
                    <!--<span class="mentor-price">${{slotProps.data.price}}</span>
                    <Button icon="pi pi-shopping-cart" label="Add to Cart" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>-->
                    <!--<span :class="'stream status-'+slotProps.data.streamStatus.toLowerCase()">{{slotProps.data.streamStatus}}</span>-->
                    <img class="twitchImg" src="~@/assets/twitch.png" v-on:click="redirect_to_twitch(slotProps.data)"/>
                </div>
            </div>
        </div>
    </template>

    <template #grid="slotProps">
        <!--<div>Vin: <b>{{slotProps.data.name}}</b></div>-->
        <div class="p-col-12 p-md-4">
            <div class="mentor-grid-item card">
                <div class="mentor-grid-item-top" v-if="isStreamLive(slotProps.data)">
                    <!--<div>
                        <i class="pi pi-tag product-category-icon"></i>
                        <span class="product-category">{{slotProps.data.category}}</span>
                    </div>-->
                    <span :class="'stream status-'+slotProps.data.streamStatus.toLowerCase()">{{slotProps.data.streamStatus}}</span>
                </div>
                <div class="mentor-grid-item-content">
                    <img :src="slotProps.data.avatar" :alt="slotProps.data.name"/>
                    <div class="mentor-name">{{slotProps.data.name}}</div>
                    <div class="mentor-description">{{slotProps.data.bio}}</div>
                    <!--<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>-->
                </div>
                <div class="product-grid-item-bottom">
                    <!--<Button icon="../assets/logo.png" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>-->
                    <img :class="'twitchimg'" src="~@/assets/twitch.png" v-on:click="redirect_to_twitch(slotProps.data)"/>
                </div>
            </div>
        </div>
    </template>
</DataView>
</template>

<script>
//import ProductService from '../../service/ProductService';
import { db } from '../../tools/firebase'
import { twitch } from '../../tools/twitch'

export default {
    data() {
        return {
            mentors: [],
            layout: 'list',
            sortKey: null,
            sortOrder: null,
            sortField: null,
            sortOptions: [
                {label: 'Price High to Low', value: '!price'},
                {label: 'Price Low to High', value: 'price'},
            ]
        }
    },
    productService: null,
    created() {
        //this.productService = new ProductService();
    },
    mounted() {
        this.getMentorsList();
        //this.productService.getProducts().then(data => this.products = data);
    },
    methods: {
        onSortChange(event){
            const value = event.value.value;
            const sortValue = event.value;

            if (value.indexOf('!') === 0) {
                this.sortOrder = -1;
                this.sortField = value.substring(1, value.length);
                this.sortKey = sortValue;
            }
            else {
                this.sortOrder = 1;
                this.sortField = value;
                this.sortKey = sortValue;
            }
        },
        getMentorsList(){
            var that=this;
            db.collection("mentors")
            .onSnapshot(function(snapshot) {
                snapshot.docChanges().forEach(function(change) {
                    if (change.type === "added") {
                        that.mentors.push(change.doc.data())
                        //that.mentors[change.doc.id]=change.doc.data()
                        //console.log(change.doc.id+" ---->>>>>>>>   "+that.mentors[change.doc.id].name);
                        //that.posts.push(change.doc.data())
                        //console.log("New city: ", change.doc.data());
                    }
                    if (change.type === "modified") {
                    that.mentors[change.doc.id]=change.doc.data()
                        //console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed") {
                    delete that.mentors[change.doc.id]
                    //that.posts.delete(change.doc.id)
                        //console.log("Removed city: ", change.doc.data());
                    }
                });
                //console.log("---->>>>>>>>   "+that.posts);
            })
        },
        redirect_to_twitch(data){
            //console.log("---->>>>>>>>   "+data.twitchurl);
            window.open("https://www.twitch.tv/"+data.twitchuser, '_blank');
        },
        async isStreamLive(data) {
            
            /*const user = await apiClient.helix.users.getUserByName(data.twitchuser);
            if (!user) {
                return false;
            }
            console.log("---->>>>>>>>   "+data.twitchuser);
            return await apiClient.helix.streams.getStreamByUserId(user.id) !== null;*/
            twitch.getStreams();
            //const streams = await twitch.getStreams({ channel: "sacriel" });
            //console.log(streams);
        }
    }
     
}
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Gorditas:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Belgrano&display=swap');

    .p-col-12 {
        background: #fff70060;
        padding: 2rem;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
        border-radius: 4px;
        margin-bottom: 2rem;
    }

    .mentor-name {
        //font-size: 1.5rem;
        //font-weight: 700;
        text-align: left;
        font-family: 'Belgrano', serif;
        font-size: 1.8vw;
        color: #152233;
        //background: #152233;
    }

    .mentor-description {
        margin: 0 0 1rem 0;
        text-align: left;
        font-family: 'Belgrano', serif;
        font-size: 1.2vw;
        color: #152233;
    }

    .twitchimg{
        width: 20px;
    }

    span.stream{
        font-size: 150%;
    }

    span.status-streaming{
        background: #a2fc81;
    }

    span.status-offline{
        background: #ff9191;
    }

    .mentor-grid-item {
        background-color: var(--surface-e);
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    }

    ::v-deep(.mentor-list-item) {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        .twitchImg{
            width: 70px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .avatarImg {
            width: 200px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .mentor-list-detail {
            flex: 1 1 0;
        }

        .p-rating {
            margin: 0 0 .5rem 0;
        }

        .mentor-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            align-self: flex-end;
        }

        .mentor-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: .5rem;
        }
    }

    @media screen and (max-width: 576px) {
	.mentor-list-item{
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.mentor-list-detail {
			text-align: center;
		}

		.mentor-price {
			align-self: center;
		}

		.mentor-list-action {
			display: flex;
			flex-direction: column;
		}

		.mentor-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}

</style>