/*const { ApiClient } = require('twitch');
const { StaticAuthProvider } = require('twitch-auth');

const clientId = 'ul1ods1q1cvvw8rxf3haxpm2tcnbkt';
const accessToken = 'ty0balnqdm9p5kp9oyg5kdafdfte3d';
const authProvider = new StaticAuthProvider(clientId, accessToken);
const apiClient = new ApiClient({ authProvider });



export {
    apiClient
}
*/


const TwitchApi = require("node-twitch").default;

const twitch = new TwitchApi({
    client_id: "ul1ods1q1cvvw8rxf3haxpm2tcnbkt",
    client_secret: "ty0balnqdm9p5kp9oyg5kdafdfte3d"
});

export {
    twitch
}

