import Firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'


// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyB52YIZPBRy2OCBcuP2jUr42Gh_FtJKhrc",
  authDomain: "eurekamps-88a51.firebaseapp.com",
  databaseURL: "https://eurekamps-88a51.firebaseio.com",
  projectId: "eurekamps-88a51",
  storageBucket: "eurekamps-88a51.appspot.com",
  messagingSenderId: "767572729650",
  appId: "1:767572729650:web:49ddd971de7366cb81012b"
}
const firebase=Firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const messaging = firebase.messaging()

//!!!!!!!!!!!!!!!!!!!!!-------------------ACTIVAR PARA RECIBIR PUSH NOTIFIACTION--------------------!!!!!!!!!!!!!!!!!!!!!!!!
/*
// Add the public key generated from the console here.
//messaging.getToken({vapidKey: "BGQwKRuMvp8SY7Q1HOIiJbqd9H_Cw1UIUZVDkZzbZ95PAn_wtQgv_Ra5ZLN18lyrj9tIERf2Ni2Nadh2v9X6ytE"})
messaging.getToken({vapidKey: 'BPIBwg3NErrTDkjSLkEt3suAqq_WThzlha8MkmfKHLpGF__JUycTR9I9Jl0VLiQhV_9LZ6JD6qqVn1PWmwwvu3k'}).then((currentToken) => {
  if (currentToken) {
    //console.log("------>>>>>>  "+currentToken);
    addTokenToDB(currentToken)
    //sendTokenToServer(currentToken);
    //updateUIForPushEnabled(currentToken);
  } else {
    // Show permission request.
    console.log('No registration token available. Request permission to generate one.');
    // Show permission UI.
    //updateUIForPushPermissionRequired();
    //setTokenSentToServer(false);
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  //showToken('Error retrieving registration token. ', err);
  //setTokenSentToServer(false);
});
// [END get_token]
*/

function addTokenToDB(currentToken){
  // Add a new document in collection "cities"
    db.collection("fcm_tokens").where("token","==",currentToken).get()
      .then(function(querySnapshot) {
        console.log("11111----->>>    "+querySnapshot.empty)
        if(querySnapshot.empty){
          
          db.collection("fcm_tokens").add({
            token: currentToken
          })
          .then(function() {
            console.log("Document successfully written!");
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });
        }
    }).catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    /*db.collection("fcm_tokens").add({
      token: currentToken
    })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });*/
}



// collection references
const usersCollection = db.collection('users')
const mentors = db.collection('mentors')
//const postsCollection = db.collection('posts')
//const commentsCollection = db.collection('comments')
//const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  mentors
  //postsCollection,
  //commentsCollection,
  //likesCollection
  
}