//import '@babel/polyfill'
//import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import TabMenu from 'primevue/tabmenu';
import MegaMenu from 'primevue/megamenu';
import { auth } from './tools/firebase';
import './tools/twitch';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Rating from 'primevue/rating';
import Button from 'primevue/button';
import FullCalendar from 'primevue/fullcalendar';
import AutoComplete from 'primevue/autocomplete';
import Card from 'primevue/card';

import axios from 'axios'
import VueAxios from 'vue-axios'
import InputText from 'primevue/inputtext';


import 'primeflex/primeflex.css';
import 'primeflex/src/_variables.scss';
import 'primeflex/src/_grid.scss';
import 'primeflex/src/_formlayout.scss';
import 'primeflex/src/_display.scss';
import 'primeflex/src/_text.scss';
import 'primeflex/src/flexbox/_flexbox.scss';
import 'primeflex/src/_spacing.scss';
import 'primeflex/src/_elevation.scss';


const app = createApp(App);
app.use(VueAxios, axios)
app.use(PrimeVue);

//createApp(App).mount('#app')
//app.component('VueTwitchPlayer', VueTwitchPlayer);
//app.component('LoadScript', LoadScript);
app.component('Sidebar', Sidebar);
app.component('TabMenu', TabMenu);
app.component('MegaMenu', MegaMenu);
app.component('DataView', DataView);
app.component('Dropdown', Dropdown);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Rating', Rating);
app.component('Button', Button);
app.component('FullCalendar', FullCalendar);
app.component('AutoComplete', AutoComplete);
app.component('InputText', InputText);
app.component('Card', Card);

auth.onAuthStateChanged(() => {
    app.mount('#app');
})

/*
Vue.filter('formatDate', function (value) {
    if (!value) return ''
    return moment(value.toString()).format('MM/DD/YYYY hh:mm')
})
*/