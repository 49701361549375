<!-- EXAMPLE USED: https://www.log2e.com/2019/02/create-a-simple-youtube-search-app-with-vue-js/-->

<template>
    <div class="container">
        <!--<Header/>-->
        <SearchForm v-on:search="search"/>
        <SearchResults
            v-if="videos.length > 0"
            v-bind:videos="videos"
            v-bind:reformattedSearchString="reformattedSearchString"
        />
<!--
        <Pagination
            v-if="videos.length > 0"
            v-bind:prevPageToken="api.prevPageToken"
            v-bind:nextPageToken="api.nextPageToken"
            v-on:prev-page="prevPage"
            v-on:next-page="nextPage"
        />
-->
    </div>

</template>

<script>
import axios from 'axios';
import Header from './Header';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import Pagination from './Pagination';

export default {
    components: {
        Header,
        SearchForm,
        SearchResults,
        Pagination
        

    },
    data() {
		return {
            videos: [],
            reformattedSearchString: '',
            api: {
                baseUrl: 'https://www.googleapis.com/youtube/v3/search?',
                part: 'snippet',
                type: 'video',
                order: 'date',
                maxResults: 20,
                q: '',
                key: 'AIzaSyA4TDhWDe2TzR4iZ6sv_U32eqSgpr6lSUE',
                prevPageToken: '',
                nextPageToken: ''
            }
        }
    },
    created: function() {
	    this.fetchVideoList();
    },
    methods: {
        fetchVideoList: function(){
            //const { baseUrl, part, type, order, maxResults, q, key, prevPageToken } = this.api;
            //const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${prevPageToken}`;
            //this.getData(apiUrl);
            //this.getData('https://www.googleapis.com/youtube/v3/playlistItems?playlistId=PLAYLISTID&key=APITKEY&fields=items&part=snippet&maxResults=15')
            /*this.$http.get('https://www.googleapis.com/youtube/v3/playlistItems?playlistId=PLAYLISTID&key=APITKEY&fields=items&part=snippet&maxResults=15', function(videosList){
                this.videos = videosList.items;
                console.log(videosList.items);
            });*/
            this.search([]);
        },
        getData(apiUrl) {
            axios.get(apiUrl)
                .then(res => {
                this.videos = res.data.items;
                this.api.prevPageToken = res.data.prevPageToken;
                this.api.nextPageToken = res.data.nextPageToken;
                })
                .catch(error => console.log(error));
        },
        search(searchParams) {
            this.reformattedSearchString = searchParams.join(' ');
            this.api.q = searchParams.join('+');
            const { baseUrl, part, type, order, maxResults, q, key } = this.api;
            const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&channelId=UCmauq_2NaJPLlH5c-sJRmeA`;
            this.getData(apiUrl);
        },
        prevPage() {
            const { baseUrl, part, type, order, maxResults, q, key, prevPageToken } = this.api;
            const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${prevPageToken}&channelId=UCmauq_2NaJPLlH5c-sJRmeA`;
            this.getData(apiUrl);
        },

        nextPage() {
            const { baseUrl, part, type, order, maxResults, q, key, nextPageToken } = this.api;
            const apiUrl = `${baseUrl}part=${part}&type=${type}&order=${order}&q=${q}&maxResults=${maxResults}&key=${key}&pageToken=${nextPageToken}&channelId=UCmauq_2NaJPLlH5c-sJRmeA`;
            this.getData(apiUrl);
        },
    }
}
</script>

<style scoped>

.container{
    background:#152233; 
}

</style>