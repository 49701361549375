<template>


    <div>
        <FullCalendar :events="events" :options="options" />
    </div>
</template>

<script>
//import ProductService from '../../service/ProductService';
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { db } from '../tools/firebase'
import { twitch } from '../tools/twitch'

export default {
    data() {
        return {
            options: {
                plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialDate: new Date(),
                dateClick: this.dateClick,
                eventClick: this.eventClicked,
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                selectable:true,
                editable: true
            },
            events: [
				/*{
					"id": 1,
					"title": "All Day Event",
					"start": "2021-04-21"
				},
				{
					"id": 2,
					"title": "Long Event",
					"start": "2021-04-13T17:00:00",

				},
				{
					"id": 3,
					"title": "Repeating Event",
					"start": "2019-01-09T16:00:00"
				},
				{
					"id": 4,
					"title": "Repeating Event",
					"start": "2019-01-16T16:00:00"
				},
				{
					"id": 5,
					"title": "Conference",
					"start": "2019-01-11",
					"end": "2019-01-13"
				},
				{
					"id": 6,
					"title": "Meeting",
					"start": "2019-01-12T10:30:00",
					"end": "2019-01-12T12:30:00"
				}*/
			]
        }
    },
    productService: null,
    created() {
        //this.productService = new ProductService();
    },
    mounted() {
        this.getEventsList();
        //this.productService.getProducts().then(data => this.products = data);
    },
    methods: {
        onSortChange(event){
            const value = event.value.value;
            const sortValue = event.value;

            if (value.indexOf('!') === 0) {
                this.sortOrder = -1;
                this.sortField = value.substring(1, value.length);
                this.sortKey = sortValue;
            }
            else {
                this.sortOrder = 1;
                this.sortField = value;
                this.sortKey = sortValue;
            }
        },
        getEventsList(){
            var that=this;
            db.collection("events")
            .onSnapshot(function(snapshot) {
                that.events=[];
                snapshot.docChanges().forEach(function(change) {
                    if (change.type === "added") {
                        var document=change.doc.data()
                        
                        document.start=that.toTimestampToCalendarDate(document.start);
                        document.end=that.toTimestampToCalendarDate(document.end);
                        

                        //console.log("-------------->>>>>>>>>>>>>>>>>    "+dformat+"   "+document.start);
                        
                        that.events.push(document)
                        //that.mentors[change.doc.id]=change.doc.data()
                        //console.log(change.doc.id+" ---->>>>>>>>   "+that.events[change.doc.id].name);
                        //that.posts.push(change.doc.data())
                        //console.log("New city: ", change.doc.data());
                    }
                    if (change.type === "modified") {
                    that.events[change.doc.id]=change.doc.data()
                        //console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed") {
                    delete that.events[change.doc.id]
                    //that.posts.delete(change.doc.id)
                        //console.log("Removed city: ", change.doc.data());
                    }
                });
                //console.log("---->>>>>>>>   "+that.posts);
            })
        },
        redirect_to_twitch(data){
            //console.log("---->>>>>>>>   "+data.twitchurl);
            window.open("https://www.twitch.tv/"+data.twitchuser, '_blank');
        },
        async isStreamLive(data) {
            
            /*const user = await apiClient.helix.users.getUserByName(data.twitchuser);
            if (!user) {
                return false;
            }
            console.log("---->>>>>>>>   "+data.twitchuser);
            return await apiClient.helix.streams.getStreamByUserId(user.id) !== null;*/
            twitch.getStreams();
            //const streams = await twitch.getStreams({ channel: "sacriel" });
            //console.log(streams);
        },
        toTimestampToCalendarDate(ts){
            var date_ob = ts.toDate();
            // year as 4 digits (YYYY)
            var year = date_ob.getFullYear();
            // month as 2 digits (MM)
            var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
            // date as 2 digits (DD)
            var date = ("0" + date_ob.getDate()).slice(-2);
            // hours as 2 digits (hh)
            var hours = ("0" + date_ob.getHours()).slice(-2);
            // minutes as 2 digits (mm)
            var minutes = ("0" + date_ob.getMinutes()).slice(-2);
            // seconds as 2 digits (ss)
            var seconds = ("0" + date_ob.getSeconds()).slice(-2);
            // date as YYYY-MM-DD format
            var dformat=year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;
            //console.log("Date as YYYY-MM-DD Format: " + year + "-" + month + "-" + date);
            return dformat;
            
        }
    }
     
}
</script>

<style scoped>
    .twitchimg{
        width: 10%;
    }

    span.stream{
        font-size: 150%;
    }

    span.status-streaming{
        background: #a2fc81;
    }

    span.status-offline{
        background: #ff9191;
    }

    .mentor-grid-item {
        background-color: var(--surface-e);
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    }

</style>