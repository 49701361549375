<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  <div class="principal">
    <Principal :width=windowWidth :height=windowHeight>
    </Principal>
  </div>
    

  
</template>

<script>
import Principal from './components/Principal.vue'

export default {
  name: 'App',
  components: {
    Principal
  },
  data() {
      return {
          windowWidth: window.innerWidth,
          windowHeight:window.innerHeight
      }
  },
  mounted() {
      window.onresize = () => {
          this.windowWidth = window.innerWidth;
          this.windowHeight=window.innerHeight;
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #152233;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.principal{
  background: #152233;
}
</style>
