<!-- EXAMPLE USED: https://www.log2e.com/2019/02/create-a-simple-youtube-search-app-with-vue-js/-->
<template>
  <div class="container">
    <!--<a :href="'https://www.youtube.com/watch?v=' + video.id.videoId" target="_blank">
      <img class="card-img-top" :src="video.snippet.thumbnails.medium.url" alt="YouTube thumbnail">
    </a>
    <div class="card-body">
      <h5 class="card-title">{{ video.snippet.title }}</h5>
      <h6
        class="card-subtitle mb-2 text-muted"
      >{{ video.snippet.channelTitle }} | {{ video.snippet.publishedAt || formatDate }}</h6>
      <p class="card-text">{{ video.snippet.description }}</p>
    </div>-->
    <Card style="width: 25em;">
        <template #header>
            <a :href="'https://www.youtube.com/watch?v=' + video.id.videoId" target="_blank">
                <img :src="video.snippet.thumbnails.medium.url" style="height: 15rem" />
            </a>
        </template>
        <template #title>
            {{ video.snippet.title }}
        </template>
        <template #subtitle>
            
            {{ video.snippet.channelTitle }} | {{ video.snippet.publishedAt || formatDate }}
            
        </template>
        <template #content>
           {{ video.snippet.description }}
        </template>
        <template #footer>
            <Button icon="pi pi-youtube" label="Ver" @click="openVideo()"/>
        </template>
    </Card>

  </div>
</template>

<script>
export default {
  name: 'VideoGridItem',
  props: ['video'],
  methods:{
      openVideo: function () {   
          window.open('https://www.youtube.com/watch?v=' + this.video.id.videoId, "_blank");    
      }
    }
  
};
</script>

<style scoped>
   /* p {
        line-height: 1.5;
        margin: 0;
    }

    .container{
        background:#152233; 
    }*/
/*
    .p-card-content{
      background:#152233; 
    }

    .p-card-title{
      background: brown;
    }
    */
</style>