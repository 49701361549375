<!-- EXAMPLE USED: https://www.log2e.com/2019/02/create-a-simple-youtube-search-app-with-vue-js/-->
<template>
  <div class="container">

            <div class="p-inputgroup">
                <InputText placeholder="Buscar" v-model="searchString" @keydown.enter.prevent="parseSearchString"/>
                <Button icon="pi pi-search" class="p-button-warning" @click="parseSearchString"/>
            </div>
      <!--
    <form class="mb-5">
      <div class="input-group">
        <input
          v-model="searchString"
          @keydown.enter.prevent="parseSearchString"
          type="text"
          class="form-control"
          placeholder="Search ..."
        >
        <div class="input-group-append">
          <button @click="parseSearchString" class="btn btn-outline-secondary" type="button">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>-->
  </div>
</template>

<script>



export default {
  name: 'SearchForm',
  data() {
    return {
      searchString: ''
    };
  },
  methods: {
    parseSearchString() {
      // Trim search string
      const trimmedSearchString = this.searchString.trim();

      if (trimmedSearchString !== '') {
        // Split search string
        const searchParams = trimmedSearchString.split(/\s+/);
        // Emit event
        this.$emit('search', searchParams);
        // Reset input field
        //this.searchString = '';
      }
    }
  }
};
</script>

<style scoped>

    .p-inputgroup{
        margin: auto;
        width: 50%;
        /*border: 3px solid green;*/
        padding: 10px;
    }


    input,
    button {
        box-shadow: none !important;
    }

    .form-control {
        border-color: #6c757d;
    }
</style>