<template>
    <!--<div style="margin-top: .5em">
        <transition-group name="dynamic-box" tag="div" class="p-grid">
            <div v-for="col of columns" :key="col" class="p-col">
                <div class="box">{{col}}</div>
            </div>
        </transition-group>
    </div>-->
    <div>
        <!--<MentorsPanel/> -->
    </div>

    <div>
        <!--<FullCalendar :events="events" :options="options" />-->
    </div>

    

    <!---->

    <div class="container" :width=windowWidth :height=windowHeight >
        <iframe
            src="https://player.twitch.tv/?channel=yony44&parent=eurekamps.com&muted=true"
            :height=playerHeight
            :width=playerWidth
            allowfullscreen="true" v-if="btnMenuPressed=='Live'">
        </iframe>
        <Kalendar v-if="btnMenuPressed=='Kalendario'"/>
        <YouTubeGallery v-if="btnMenuPressed=='Tutoriales'"/>
        <Ekipo v-if="btnMenuPressed=='Ekipo'"/>
        <!--<FullCalendar :events="events" :options="options" v-if="btnMenuPressed=='Kalendario'"/>-->
    </div>


</template>

<script>
import Ekipo from './Ekipo/Ekipo'
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import YouTubeGallery from './YoutubeGallery/YouTubeGallery'
import Kalendar from './Kalendar'

export default {

    components: {
        Ekipo,
        YouTubeGallery,
        Kalendar
    },
    props: ['btnMenuPressed'],
    data() {
		return {
            windowWidth: window.innerWidth*0.9,
            windowHeight:window.innerHeight*0.9,
            playerWidth:window.innerWidth*0.65,
            playerHeight:window.innerHeight*0.65,

            options: {
                plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialDate: '2019-01-01',
                dateClick: this.dateClick,
                eventClick: this.eventClicked,
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                selectable:true,
                editable: true
            },
			events: [
				{
					"id": 1,
					"title": "All Day Event",
					"start": "2019-01-01"
				},
				{
					"id": 2,
					"title": "Long Event",
					"start": "2019-01-07",
					"end": "2019-01-10"
				},
				{
					"id": 3,
					"title": "Repeating Event",
					"start": "2019-01-09T16:00:00"
				},
				{
					"id": 4,
					"title": "Repeating Event",
					"start": "2019-01-16T16:00:00"
				},
				{
					"id": 5,
					"title": "Conference",
					"start": "2019-01-11",
					"end": "2019-01-13"
				},
				{
					"id": 6,
					"title": "Meeting",
					"start": "2019-01-12T10:30:00",
					"end": "2019-01-12T12:30:00"
				}
			]
		};
	},
    
    methods:{
        dateClick: function(info) {
            //alert('date click! ' + info.dateStr)
        },
        eventClicked:function(info){
            alert('HEY!!!!! '+info.event.title+"     "+info.event.start);
        }
    },
    mounted() {
      window.onresize = () => {
          this.windowWidth = window.innerWidth*0.9;
          this.windowHeight=window.innerHeight*0.9;
          this.playerWidth=window.innerWidth*0.65;
            this.playerHeight=window.innerHeight*0.65;
      }
    }
     
}
</script>

<style scoped>
    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: var(--surface-d);
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active, .dynamic-box-leave-active {
        transition: all .5s;
    }

    .dynamic-box-enter, .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter, .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
    }


    .container {
        margin:100px;
        width: windowWidth;
        height: 100vh;
        max-height: 100%;
        margin: 0;
        padding: 0;
        /*background-size:cover;
        background-repeat: no-repeat;
        background-image: url('~@/assets/EurekampsLogo2Clean.png');*/
    }

    
</style>