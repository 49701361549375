<template>
    <div class="p-grid p-jc-center p-ai-center">
        <div class="p-col1">
            <div class="title">
                <a>EUREKAMPS</a>
            </div>
        </div>
        <div class="p-col2">
            <img src="~@/assets/EurekampsLogo2Clean.png" width="150" height="150"/>
        </div>
    </div>

    <div class="subtitle">
        <a>Kursos tecnológicos gratuitos para tod@s... síguenos en Twitch, YouTube y Discord</a>
    </div>

    <div class="menu">
        <Button ref="btnFirst" label="@Twitch" class="p-button-rounded" @click="onButtonClicked('Live',$event)"/>
        <Button label="@YouTube" class="p-button-rounded" @click="onButtonClicked('Tutoriales',$event)"/>
        <Button label="Kalendario" class="p-button-rounded" @click="onButtonClicked('Kalendario',$event)"/>
        <Button label="Komunidad" class="p-button-rounded" @click="onButtonClicked('Komunidad',$event)"/>
        <Button label="Ekipo" class="p-button-rounded" @click="onButtonClicked('Ekipo',$event)"/>
        <!--<Button label="Secondary" class="p-button-rounded p-button-secondary" />
        <Button label="Success" class="p-button-rounded p-button-success" />
        <Button label="Info" class="p-button-rounded p-button-info" />
        <Button label="Warning" class="p-button-rounded p-button-warning" />
        <Button label="Help" class="p-button-rounded p-button-help" />
        <Button label="Danger" class="p-button-rounded p-button-danger" />-->
    </div>
    
    <div class="p-shadow-24" v-if="false">
        <MegaMenu :model="items" />
    </div>


    

</template>

<script>


export default {
     data() {
        return {
            items: [
                {
                    label: 'Twitch', icon: 'pi pi-fw pi-video',
                    /*items: [
                        [
                            {
                                label: 'Video 1',
                                items: [{label: 'Video 1.1'}, {label: 'Video 1.2'}]
                            },
                            {
                                label: 'Video 2',
                                items: [{label: 'Video 2.1'}, {label: 'Video 2.2'}]
                            }
                        ],
                        [
                            {
                                label: 'Video 3',
                                items: [{label: 'Video 3.1'}, {label: 'Video 3.2'}]
                            },
                            {
                                label: 'Video 4',
                                items: [{label: 'Video 4.1'}, {label: 'Video 4.2'}]
                            }
                        ]
                    ]*/
                },
                {
                    label: 'Tutoriales', icon: 'pi pi-fw pi-video',
                    /*items: [
                        [
                            {
                                label: 'User 1',
                                items: [{label: 'User 1.1'}, {label: 'User 1.2'}]
                            },
                            {
                                label: 'User 2',
                                items: [{label: 'User 2.1'}, {label: 'User 2.2'}]
                            },
                        ],
                        [
                            {
                                label: 'User 3',
                                items: [{label: 'User 3.1'}, {label: 'User 3.2'}]
                            },
                            {
                                label: 'User 4',
                                items: [{label: 'User 4.1'}, {label: 'User 4.2'}]
                            }
                        ],
                        [
                            {
                                label: 'User 5',
                                items: [{label: 'User 5.1'}, {label: 'User 5.2'}]
                            },
                            {
                                label: 'User 6',
                                items: [{label: 'User 6.1'}, {label: 'User 6.2'}]
                            }
                        ]
                    ]*/
                },
                {
                    label: 'Calendario', icon: 'pi pi-fw pi-calendar',
                    /*items: [
                        [
                            {
                                label: 'Event 1',
                                items: [{label: 'Event 1.1'}, {label: 'Event 1.2'}]
                            },
                            {
                                label: 'Event 2',
                                items: [{label: 'Event 2.1'}, {label: 'Event 2.2'}]
                            }
                        ],
                        [
                            {
                                label: 'Event 3',
                                items: [{label: 'Event 3.1'}, {label: 'Event 3.2'}]
                            },
                            {
                                label: 'Event 4',
                                items: [{label: 'Event 4.1'}, {label: 'Event 4.2'}]
                            }
                        ]
                    ]*/
                },
                {
                    label: 'Eurekamps', icon: '../assets/logo.png',
                    /*items: [
                        [
                            {
                                label: 'Setting 1',
                                items: [{label: 'Setting 1.1'}, {label: 'Setting 1.2'}]
                            },
                            {
                                label: 'Setting 2',
                                items: [{label: 'Setting 2.1'}, {label: 'Setting 2.2'}]
                            },
                            {
                                label: 'Setting 3',
                                items: [{label: 'Setting 3.1'}, {label: 'Setting 3.2'}]
                            }
                        ],
                        [
                            {
                                label: 'Setting 4',
                                items: [{label: 'Setting 4.1'}, {label: 'Setting 4.2'}]
                            }
                        ]
                    ]*/
                }
            ]
        }
    },
    mounted() {
        this.$refs.btnFirst.$el.focus();
    },
    created(){
        //this.$refs.btnFirst.focus();
    },
    methods:{
        onButtonClicked(btn,evt){
            if(btn!='Komunidad'){
                this.$emit('menuBtnClicked', btn);
                this.btnSelected(evt);
            }
            else{
                this.openComunidad();
            }
            
        },
        btnSelected(btn){
            //alert("-------------->>>>>>>>>>>>>>>>>> "+btn.target);
        },
        openComunidad: function () {   
          window.open('https://discord.gg/newC6gU8PR');    
        }
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Gorditas:wght@400;700&display=swap');
    
    .title{
        font-family: 'Gorditas', cursive;
        font-size: 6vw;
        color: #fff500;
        background: #152233;
    }


    .p-button{
        background: #fff500;
        color: #152233;
        font-family: 'Gorditas', cursive;
        width: 15%;
        margin: 1%;
        font-size: 170%;
    }

    .p-button:focus {
        background-color: white;
    }

    .subtitle{
        font-family: 'Gorditas', cursive;
        font-size: 1.8vw;
        color: #fff500;
        background: #152233;
    }

</style>